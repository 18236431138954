.tua-testimonials {
  position: relative;
  background-color: #33332B;
  padding-top: 150px;
  padding-bottom: 155px;
  @media (min-width: 768px) {
    padding-bottom: 0;

  }
  @media (min-width: 1920px) {
    padding-top: 239px;
  }
  &__title {
    position: relative;
    margin-bottom: 40px;
    width: 100% !important;
    @media (min-width: 1300px) {
      margin-bottom: 0;
      width: calc(25% - 20px) !important;
    }
    &:after {
      display: none;
      content: '';
      position: absolute;
      bottom: 159/705*100%;
      width: 58px;
      height: 58px;
      background: url("../img/testimonials-decor.svg") center no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      @media (min-width: 1300px) {
        display: block;
      }
    }
  }
  .tua-col-3 {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    width: 100%;
    @media (min-width: 992px) and (max-width: 1300px) {
      padding-left: 10px;
      padding-right: 10px;
      width: 33.33%;
    }
    @media (min-width: 1300px) {
      width: 25%;
    }
  }
  .tua-row {
    margin-left: -10px;
    margin-right: -10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 134px;
    background: url("../img/bg.png") repeat-x;
    top: -5px;
  }
}

.tua-testimonial {
  position: relative;
  //padding: 80px 40px 40px;
  color: #fff;
  box-sizing: border-box;
  margin-bottom: 20px;
  &-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 50px;
    max-width: 790px;
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 30px;
    }
    p {
      a {
        color: #D6D601;
        transition: .35s;
        &:hover,
        &:focus,
        &:active {
          opacity: .8;
          text-decoration: underline;
        }
      }
      @media (max-width: 768px){
        font-size: 18px;
        line-height: 24px;
      }
    }
    &__footer {
      .tua-testimonial__link {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.03em;
        text-decoration-line: underline;
        color: #D6D601;
        transition: .35s;
        &:hover,
        &:focus,
        &:active {
          opacity: .8;
          text-decoration: none;
        }
        @media (min-width: 1200px) {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 50px;
  }
  @media (min-width: 992px) {
    //padding: 106px 40px 160px;
    //min-height: 550px;
  }
  @media (min-width: 1200px) {
    display: flex !important;
  }
  .btn {
    min-width: 100%;
    margin-right: 34px;
    cursor: pointer;
    box-sizing: border-box;
    @media (min-width: 330px) {
      min-width: 127px;
    }
    @media (min-width: 768px) {
      margin-right: 70px;
      min-width: 164px;
    }
  }
}

.tua-testimonial-author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  //margin-top: 80px;
  /*@media (min-width: 992px) {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    margin-top: 0;
  }*/
  @media (min-width: 1200px) {
    padding-right: 240px;
    margin-bottom: 50px;
    flex-wrap: inherit;
  }
  &-img {
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: 1200px) {
      width: auto;
      margin-bottom: 0;
    }
    img {
      display: inline-block;
      max-width: 127px;
      height: auto;
      object-fit: contain;
      @media (min-width: 768px) {
        max-width: 303px;
        margin-right: 100px;
      }
    }
  }
  &__img-wrap {
    width: 127px;
    height: 127px;
    background: #FFFFFF;
    margin-right: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      width: 222px;
      height: 222px;
    }
    @media (min-width: 992px) and (max-width: 1300px) {
      margin-right: 50px;
    }
    @media (min-width: 1300px) {
      margin-right: 100px;
    }
  }
  &__info {
    @media (min-width: 1200px){
      padding-right: 170px;
    }
    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: block;
      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
    span {
      display: block;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 54px;
    }
    a {
      &:hover {
        path {
          fill: #D6D601;
        }
      }
    }
  }
  &__name {
    display: flex;
    >strong {
      margin-right: 14px;
      display: flex;
      align-items: center;
      @media (min-width: 992px) {
        display: initial;
      }
    }
  }
  &__link {
    transition: .35s;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -3px;
    @media (min-width: 992px) {
      position: static;
      top: 0;
      margin-left: 10px;
    }
    &:hover{
      text-decoration: none;
      path {
        fill: #0271AE !important;
        &:last-child,
        &:nth-child(2){
          fill: #fff !important;
        }
      }
    }
  }
}