/* The Overlay (background) */
.tua-overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  left: 0;
  top: 0;
  background: #33332B;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.3s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.tua-overlay-content {
  position: absolute;
  width: 100%;
  padding-left: 18px;
  margin-top: 30px;
  padding-right: 18px;
  box-sizing: border-box;
  bottom: 120px;
  top: 25%;
  text-align: center;
}

/* The navigation links inside the overlay */
.tua-overlay a {
  text-decoration: none;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  margin: 0 0 40px;
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 32px;
    line-height: 32px;
  }
  span {
    display: inline-block;
    line-height: 1.3;
    letter-spacing: normal;
    border-bottom: 2px solid #fff;
  }
}

/* When you mouse over the navigation links, change their color */
.tua-overlay a:hover, .tua-overlay a:focus {
  opacity: .7;
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.tua-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 30px;
  @media (max-width: 450px){
    font-size: 17px;
    top: 30px;
    right: 18px;
  }
}

.tua-toggle-menu {
  position: absolute;
  top: 5px;
  right: 0;
  width: 16px;
  height: 16px;
  font-size: 0;
  z-index: 9999;
  line-height: 1;
  cursor: pointer;
  span {
    width: 16px;
    height: 2px;
    margin-bottom: 3px;
    display: inline-block;
    background: #33332B;
    border-radius: 1px;
  }
  @media (min-width: 768px) {
    span {
      width: 30px;
      height: 3px;
    }
  }
  @media (min-width: 768px) {
    width: 30px;
  }
  @media (min-width: 1200px) {
    display: none;
  }
}