/* Customize the label (the container) */
.rivet-checkbox-container {
  display: block;
  position: relative;
  padding-left: 33px;
  padding-top: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (min-width: 992px) {
    padding-left: 42px;
    font-size: 22px;
  }
}

/* Hide the browser's default checkbox */
.rivet-checkbox-container  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.rivet-checkbox-container  .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: #fff;
  @media (min-width: 992px) {
    width: 27px;
    height: 27px;
  }
}

/* On mouse-over, add a grey background color */
.rivet-checkbox-container :hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.rivet-checkbox-container  input:checked ~ .checkmark {
    //background-color: $dirty-purple;
}

/* Create the checkmark/indicator (hidden when not checked) */
.rivet-checkbox-container  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.rivet-checkbox-container  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.rivet-checkbox-container  .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  @media (min-width: 992px) {
    left: 9px;
    top: 5px;
  }
}