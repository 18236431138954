.tua-main-screen {
  background: #fff;
  @media (min-width: 576px) and (max-width: 1920px) {
    padding-bottom: 60px;
  }
  h2 {
    color: #33332B;
    @media (min-width: 992px) {
      margin-top: 30px;
    }
    @media (min-width: 1200px) {
      margin-top: 90px;
    }
    @media (min-width: 1920px) {
      margin-top: 130px;
    }
  }
  .tua-col-5 {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 992px) {
      width: 45%;
      &:after {
        display: none;
        content: '';
        width: 31px;
        height: 41px;
        position: absolute;
        bottom: 0;
        background: url("../img/arrow_main_screen.svg") no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        @media (min-width: 992px) {
          //display: block;
        }
        @media (min-width: 992px) and (max-width: 1368px) {
          width: 21px;
          height: 31px;
        }
      }
    }
  }
  .tua-col-7 {
    box-sizing: border-box;
    width: 100%;
    @media (min-width: 576px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: 992px) {
      width: 55%;
    }
  }
  .tua-frame-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}