.slick-prev, .slick-next {
  position: absolute;
  width: 48px;
  height: 48px;
  z-index: 9;
  border-radius: 100%;
  background: #FFFFFF;
  outline: none;
  border: none;
  top: 80px;
  right: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .35s;
  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    top: 0;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    top: 235px;
  }
  &:before {
    content: '';
    width: 14px;
    height: 10px;
    background: url('../img/next.svg') no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
  }
  &:hover,
  &:active,
  &:focus {
    opacity: .9;
  }
}

.slick-next {
  background: #D6D601;
  @media (max-width: 1200px){
    right: 0 !important;
  }
}
.slick-prev {
  right: 55px;
  transform: rotate(-180deg);
  @media (min-width: 768px) {
    right: 81px;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    right: 80px !important;
  }
}

.mfp-content {
  .slick-prev {
    @media (min-width: 768px) {
      right: 162px;
    }
  }
  .slick-next {
    @media (min-width: 768px) {
      right: 81px;
    }
  }
}