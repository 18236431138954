.tua-main-header-wrap {
  background: #fff;
  margin-bottom: 40px;
  min-height: 72px;

  @media (min-width: 1200px) {
    margin-bottom: 0;
    min-height: 266px;
  }
}

#tua-main-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;

  &.sticky {
    position: fixed;
  }
}

.tua-main-header {
  background: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  transition: .3s ease;

  .logo {
    display: inline-block;
    text-indent: -9999px;
    width: 164px;
    height: 24px;
    background: url("../img/logo.png") center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;

    @media (min-width: 576px) {
      width: 222px;
      height: 32px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tua-social-link {
    a {
      @media (max-width: 1200px) {
        filter: brightness(100);
      }
    }
  }
}

/* Style the navigation menu */
.topnav {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Style navigation menu links */
.topnav a {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #33332B;
  transition: color .3s;

  &:hover,
  &:active,
  &:focus {
    color: #D6D601;
  }
}

.main-nav {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }

  &__ul {
    display: flex;
  }

  &__li {
    margin-left: 15px;
    margin-right: 15px;

    @media (min-width: 1920px) {
      margin-left: 40px;
      margin-right: 40px;
    }

    align-self: center;
  }
}

.tua-social-links {
  display: block;

  @media (max-width: 1200px) {
    display: none;
    position: fixed;
    bottom: 26px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
  }

  &__list {
    display: flex;
    margin-right: 20px;
  }
}

.tua-social-link {
  a {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 42px;
    padding: 0;

    @media (min-width: 1200px) {
      &:hover {
        .tua-social-link_medium {
          path {
            fill: #02B875;
          }
        }

        .tua-social-link_linkedin {
          fill: #0077B5;
        }

        .tua-social-link_facebook {
          fill: #3B3598;
        }

        .tua-social-link_vimeo {
          fill: #1AB5EA;
        }
      }
    }
  }
}


/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  .tua-main-header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}