.services {
  position: relative;
  background-color: #33332B;
  color: #ffffff;
  box-sizing: border-box;
  h3 {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      margin-bottom: 100px;
    }
  }
  &__inner {
    padding-top: 40px;
    @media (min-width: 1200px) {
      padding-top: 301px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 576px) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }
  &__item {
    width: 100%;
    margin-bottom: 35px;
    box-sizing: border-box;
    @media (min-width: 576px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    p {
      color: rgba(255,255,255, .7);
      @media (min-width: 768px) {
        padding-top: 0;
      }
      @media (max-width: 768px){
        font-size: 16px;
        line-height: 24px;
      }
    }
    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 1400px) {
      width: 25%;
    }
    &_video {
      h4::before {
        background: url("../img/video.svg") no-repeat;
      }
    }
    &_cloud {
      h4::before {
        background: url("../img/cloud.svg") no-repeat;
      }
    }
    &_mobile {
      h4::before {
        background: url("../img/mobile.svg") no-repeat;
      }
    }
    &_hardware {
      h4::before {
        background: url("../img/hardware.svg") no-repeat;
      }
    }
    &:nth-child(3) {
      h4 {
        padding-left: 50px;
        @media (min-width: 768px) {
          padding-left: 72px;
        }
      }
    }
  }
  h4 {
    position: relative;
    padding-left: 60px;
    color: #D6D601;
    margin-bottom: 20px;
    font-weight: 500;
    &:before {
      -webkit-background-size: contain;
      background-size: contain;
      content: '';
      position: absolute;
      left: 0;
      height: 45px;
      width: 47px;
    }
    @media (min-width: 768px) {
      padding-left: 97px;
      &:before {
        height: 75px;
        width: 77px;
      }
    }
  }
  &__decorator {
    position: absolute;
    display: none;
    top: -60px;
    right: 447/1920*100%;
    width: 361px;
    height: 361px;
    border-radius: 100%;
    background: #FFFFFF;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 102px;
      height: 64px;
      background: url("../img/logo_our_service.svg") center no-repeat;
      background-size: cover;
      transform: translate(-50%, -50%);
    }
    @media (min-width: 1300px) and (max-width: 1919px) {
      display: block;
      width: 300px;
      height: 300px;
    }
    @media (min-width: 1920px) {
      display: block;
    }
  }
  &__scroll-down {
    position: absolute;
    display: none;
    bottom: -39px;
    right: 528/1920*100%;
    height: 199px;
    width: 199px;
    border-radius: 100%;
    background-color: #fff;
    z-index: 99;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 30px;
      height: 38px;
      background: url("../img/arrow_bottom.svg") center no-repeat;
      background-size: contain;
      transform: translate(-50%, -50%);
    }
    @media (min-width: 1200px) and (max-width: 1919px) {
      display: block;
      height: 100px;
      width: 100px;
    }
    @media (min-width: 1920px) {
      display: block;
    }
  }
  footer {
    display: flex;
    @media (min-width: 768px) {
      justify-content: center;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}