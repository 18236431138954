.tua-get-started {
  position: relative;
  min-height: 300px;
  padding-top: 40px;
  background: #fff;
  @media (min-width: 767px) {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  @media (min-width: 992px) {
    padding-top: 250px;
  }
  &__decor {
    display: none;
      content: '';
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 304px;
      height: calc(100% + 2px);
      background: #D6D601;
    @media (min-width: 767px) {
      display: block;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      width: 170px;
    }
    span {
      position: absolute;
      left: -200px;
      width: 200px;
      height: 201px;
      background: #D6D601;
      overflow: hidden;
      top: 0;
      &:before {
        content: '';
        position: absolute;
        top: 2px;
        right: 0;
        width: 400px;
        height: 400px;
        border-radius: 100%;
        background: #fff;
      }
      &:last-child {
        left: initial;
        right: -200px;
        &:before {
          right: initial;
        }
      }
    }
    }
  &__item {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    position: relative;
    margin-bottom: 57px;
    box-sizing: border-box;
    &:first-child {
      .tua-get-started__text {
        padding-left: 65px;
      }
    }
    &:last-child {
      .tua-get-started__text {
        @media (max-width: 767px){
          padding-left: 105px;
        }
      }
    }
    @media (max-width: 767px){
      flex-wrap: wrap;
      &:nth-child(even) {
        flex-direction: column-reverse;
      }
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      &:first-child {
        .tua-get-started__text {
          padding-left: 50px;
        }
      }
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
      &:first-child {
        .tua-get-started__text {
          padding-left: 85px;
        }
      }
    }
    @media (min-width: 1400px) and (max-width: 1920px) {
      &:first-child {
        .tua-get-started__text {
          padding-left: 85px;
        }
      }
    }
    @media (min-width: 1920px) {
      &:first-child {
        .tua-get-started__text {
          padding-left: 140px;
        }
      }
    }
  }
  &__img {
    margin-bottom: 40px;
    img {
      @media (min-width: 992px) and (max-width: 1200px) {
        margin-left: 48px;
        max-width: 315px;
      }
      @media (min-width: 1200px) and (max-width: 1300px) {
        margin-left: 35px;
      }
      @media (min-width: 1300px) and (max-width: 1400px) {
        margin-left: 20px;
      }
      @media (min-width: 1400px) and (max-width: 1700px) {
        margin-left: 25px;
      }
    }
    &_reverse {
      img {
        @media (min-width: 992px) and (max-width: 1200px) {
          margin-left: 3px;
          margin-right: 50px;
        }
        @media (min-width: 1200px) and (max-width: 1400px) {
          margin-left: 0;
          margin-right: 15px;
          max-width: 100%;
        }
        @media (min-width: 1400px) and (max-width: 1700px) {
          margin-left: 0;
        }
      }
    }
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &__img, &__title {
    width: 100%;
    @media (min-width: 767px) {
      width: 646/1640*100%;
    }
    @media (min-width: 768px) and (max-width: 1700px) {
      width: 36.39024%;
    }
    h3 {
      color: #33332B;
      font-size: 24px;
      line-height: 32px;
      @media (min-width: 767px) {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
  &__title {
    margin-bottom: 40px;
    @media (min-width: 767px) {
      margin-bottom: 0;
    }
  }
  &__desc {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    @media (min-width: 767px) {
      width: 996/1640*100%;
      padding-right: 20px;
    }
    .number {
      font-style: normal;
      font-weight: bold;
      font-size: 100px;
      line-height: 150px;
      letter-spacing: -0.03em;
      color: #D6D601;
      position: absolute;
      top: -65px;
      left: -45px;
      @media (min-width: 767px) {
        line-height: 150px;
        font-size: 150px;
        color: #FFFFFF;
        position: static;
        padding-left: 76px;
      }
      @media (min-width: 768px) and (max-width: 992px) {
        font-size: 100px;
        line-height: 150px;
        padding-left: 0;
      }
    }
    &_reverse {
      flex-direction: initial;
      .number {
        padding-right: 76px;
        padding-left: 0;
        @media (min-width: 768px) and (max-width: 992px) {
          padding-right: 20px;
        }
      }
      .tua-get-started__text {
        padding-top: 0;
        @media (min-width: 768px) and (max-width: 1200px) {
          padding-left: 30px;
        }
        @media (min-width: 1200px) {
          padding-top: 60px;
          padding-left: 65px;
        }
        @media (min-width: 1400px) and (max-width: 1920px) {
          padding-top: 60px;
          padding-left: 65px;
        }
        @media (min-width: 1920px) {
          padding-top: 60px;
          padding-left: 115px;
        }
      }
    }
  }
  &__text {
    padding-top: 0;
    position: relative;
    width: 100%;
    padding-left: 90px;
    @media (min-width: 767px) {
      padding-top: 110px;
      padding-left: 0;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      padding-top: 60px;
    }
    @media (min-width: 1920px) {
      padding-left: 215px;
    }
    ul {
      list-style-type: none;
      li {
        position: relative;
        padding-left: 15px;
        font-size: 16px;
        @media (min-width: 767px) {
          font-size: 18px;
        }
      &:before {
        content: '\2022';
        position: absolute;
        top: -4px;
        left: 0;
        font-size: 36px;
        }
      }
    }
  }
  h4 {
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
}