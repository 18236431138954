.main-footer {
  position: relative;
  padding-top: 40px;
  padding-bottom: 95px;
  border-top: 1px solid rgba(255,255,255, .3);
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;
  color: rgba(255,255,255, .7);
  background-color: #33332B;
  @media (min-width: 992px) {
    padding-top: 63px;
    padding-bottom: 43px;
  }
  .tua-row{
    >div {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 40px;
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
      @media (max-width: 768px){
          box-sizing: border-box;
      }
    }
  }
  &__copyright {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    @media (min-width: 992px) {
      position: static;
      width: calc(435/1780*100% - 30px);
    }
    @media (min-width: 992px) and (max-width: 1300px) {
      font-size: 14px;
    }
    p {
      margin-top: 17px;
    }
  }
  &__contact {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255, .3);
    @media (max-width: 768px){
        flex-wrap: wrap;
    }
    @media (min-width: 992px) {
      width: calc(797/1780*100% - 30px);
      padding-bottom: 0;
      border-bottom: none;
    }
    >div {
      margin-right: 70px;
      margin-bottom: 24px;
      width: 100%;
      @media (min-width: 768px) {
        width: auto;
      }
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
      @media (min-width: 992px) and (max-width: 1300px) {
        margin-right: 20px;
      }
      @media (min-width: 1300px) and (max-width: 1919px) {
        margin-right: 35px;
      }
      strong {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.03em;
        color: #D6D601;
      }
      a {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        transition: color .3s;
        @media (min-width: 992px) {
          font-size: 30px;
        }
        @media (min-width: 992px) and (max-width: 1300px) {
          font-size: 19px;
        }
        @media (min-width: 1300px) and (max-width: 1919px) {
          font-size: 24px;
        }
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
          color: #D6D601;
        }
      }
    }
  }
  &__social {
    width: 100%;
    @media (min-width: 992px) {
      width: calc(548/1780*100% - 30px);
    }
    .tua-social-links__list_footer {
      @media (min-width: 992px) {
        justify-content: flex-end;
      }
      li {
        margin-right: 10px;
        @media (min-width: 992px) {
          margin-left: 10px;
          margin-right: 0;
        }
        @media (min-width: 992px) and (max-width: 1300px) {
          width: 50px;
          height: 50px;
        }
        a {
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          background-color: #FFFFFF;
          @media (min-width: 992px) and (max-width: 1300px) {
            width: 50px;
            height: 50px;
          }
          &:hover {
            filter: none;
          }
        }
      }
    }
  }
}