.tua-hello-bar {
  background: #212121;
  &__container {
    min-height: 80px;
    position: relative;
    padding: 0 0 0 173px;
    max-width: 360px;
    margin: 0 auto;
    color: #ffffff;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    strong {
      color: #FDD400;
      font-weight: 700;
    }
    b {
      color: #1E7FE5;
    }
    a {
      color: #FFE76B;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 173px;
      height: 80px;
      background: url("../img/WeStandWUkr.png") no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 157px;
      height: 102px;
      background: url("../img/Ukraine.png");
      -webkit-background-size: cover;
      background-size: cover;
      display: none;
    }
    @media screen and (min-width: 768px) {
      font-size: 14px;
      min-height: 86px;
      padding: 6px 130px 6px 238px;
      max-width: calc(768px - 74px * 2);
      &::before {
        width: 225px;
        height: 86px;
        -webkit-background-size: contain;
        background-size: contain;
      }
      &:after {
        display: block;
        width: 124px;
        height: 80px;
        -webkit-background-size: contain;
        background-size: contain;
      }
    }
    @media screen and (min-width: 992px) {
      font-size: 20px;
      min-height: 120px;
      padding: 9px calc(55px + 157px) 9px calc(292px + 41px);
      max-width: calc(1440px - 263px * 2);
      &::before {
        width: 292px;
        height: 120px;
      }
      &:after {
        width: 157px;
        height: 102px;
      }
    }
  }
}