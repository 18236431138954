.choose-teaminua {
  background-color: #444;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #ffffff;
  line-height: 35px;
  padding-top: 40px;
  padding-bottom: 0px;
  //width: 4130px;
  overflow: hidden;
  @media (min-width: 1200px) {
    padding-top: 150px;
    padding-bottom: 10px;
  }
  h3 {
    margin-bottom: 50px;
    @media (max-width: 768px){
        img {
          max-width: 128px;
        }
    }
  }
  &__boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;
    @media (min-width: 1400px) {
      margin-left: -50px;
      margin-right: -50px;
    }
  }
  &__icon {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin-bottom: 15px;
    background: url("../img/talents.svg") no-repeat;
    @media (min-width: 576px) {
      margin-bottom: 20px;
    }
    &-wrap {
      line-height: 1;
    }
  }
  &__box{
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
    border-bottom: 1px solid #D6D601;
    margin-bottom: 30px;
    padding-bottom: 15px;
    @media (min-width: 576px) {
      padding-bottom: 40px;
      margin-bottom: 65px;
      box-sizing: border-box;
    }
    @media (min-width: 576px) and (max-width: 992px) {
      width: calc(50% - 50px);
      min-height: 155px;

    }
    @media (min-width: 992px) and (max-width: 1400px) {
      width: calc(25% - 50px);
      min-height: 200px;
    }
    @media (min-width: 1400px) {
      width: calc(25% - 100px);
      margin-left: 50px;
      margin-right: 50px;
      min-height: 240px;
    }
    &:first-child, &:nth-child(5) {
      .choose-teaminua__icon {
        height: 51px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(2){
      .choose-teaminua__icon {
        background: url("../img/professional.svg") no-repeat;
        height: 52px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(3){
      .choose-teaminua__icon {
        background: url("../img/robust.svg") no-repeat;
        height: 51px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(4){
      .choose-teaminua__icon {
        background: url("../img/choose4.svg") no-repeat;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(5){
      .choose-teaminua__icon {
        background: url("../img/choose5.svg") no-repeat;
      }
    }
    &:nth-child(6){
      .choose-teaminua__icon {
        background: url("../img/choose6.svg") no-repeat;
        height: 57px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(7){
      .choose-teaminua__icon {
        background: url("../img/choose7.svg") no-repeat;
        height: 43px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
    &:nth-child(8){
      .choose-teaminua__icon {
        background: url("../img/choose8.svg") no-repeat;
        height: 54px;
        @media (min-width: 576px) {
          height: 64px;
        }
      }
    }
  }
  &__desc {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    br {
      display: none;
    }
    @media (min-width: 576px) {
         br {
           display: block;
         }
    }
    @media (min-width: 1400px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
}

.text-indent {
  display: inline-block;
  text-indent: -99999px;
}