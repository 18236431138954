.btn {
  display: inline-block;
  text-align: center;
  padding: 14px 20px;
  background: #D6D601;
  border-radius: 40px;
  font-weight: bold;
  font-size: 16px;
  color: #33332B;
  margin-bottom: 20px;
  width: auto;
  transition: background-color, color .35s;
  position: relative;
  z-index: 1000;

  &_grey {
    background-color: #f6f6f6;
  }

  &:hover {
    background-color: #000;
    border-color: #000;
    color: #D6D601;

  }

  &_mob {
    margin-top: 37px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    margin-bottom: 60px;
    padding: 25px 30px;
    min-width: 176px;
  }
}

.btn-small {
  display: inline-block;
  text-align: center;
  padding: 8px 8px;
  background: #D6D601;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #33332B;
  width: auto;
  transition: background-color, color .35s;

  &_grey {
    background-color: #f6f6f6;
  }

  &:hover {
    background-color: #000;
    border-color: #000;
    color: #D6D601;

  }

  &_mob {
    margin-top: 37px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 8px 8px;
    min-width: 120px;
  }
}