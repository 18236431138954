.tua-get-in-touch {
  text-align: center;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #33332B;

  @media (min-width: 768px) {
    padding-top: 149px;
    padding-bottom: 100px;
  }

  h3 {}

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    border: 152px solid transparent;
    border-top: 60px solid #D6D601;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      border: 86px solid transparent;
      border-top: 60px solid #D6D601;
    }
  }
}

._2LpwQ___Wrapper__cls1._1cMla___Wrapper__media-tablet-up,
._2LpwQ___Wrapper__cls1._1cMla___Wrapper__media-tablet-up {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.tua-get-in-touch-link-wrap {
  text-align: left;

  @media (min-width: 768px) {
    text-align: center;
  }
}

.calendly-inline-widget {
  @media (max-width: 350px) {
    min-width: 100% !important;
  }
}