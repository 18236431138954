.tua-engagement-models {
  position: relative;
  background: #33332B;
  padding-bottom: 20px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 615px;
    background: url("../img/bg_engagements.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .tua-container {
    position: relative;
    z-index: 3;
  }
  @media (min-width: 768px) {
    padding-top: 90px;
    padding-bottom: 101px;
  }
  @media (min-width: 992px) {
    padding-top: 170px;
    background: #fff;
  }
  h3 {
    position: relative;
    z-index: 9;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.03em;
    color: #fff;
    margin-bottom: 40px;
    margin-top: -75px;
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      background: #000;
    }
    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 45px;
      margin-top: 0;
    }
    @media (min-width: 992px) {
      color: #33332B;
    }
    @media (min-width: 1300px) {
      margin-bottom: 0;
    }
    @media (min-width: 2880px) {
      padding-top: 45px;
    }
  }
  .tua-col-3 {
    width: 100% !important;
    margin-bottom: 20px;
    box-sizing: border-box;
    &:first-child {
      @media (min-width: 992px) and (max-width: 1300px) {
        width: 100% !important;
      }
    }
      &:nth-child(2) {
        .tua-model {
          &:before {
            background: url("../img/engagement2.svg") no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
          }
        }
      }
      &:last-child {
        .tua-model {
          &:before {
            background: url("../img/engagement3.svg") no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
          }
        }
      }
    @media (min-width: 992px) and (max-width: 1300px) {
      width: 33.33% !important;
    }
    @media (min-width: 1300px) {
      flex: 1;
      width: calc(25% - 30px) !important;
    }
  }
  &__decor {
      position: absolute;
      top: -2px;
      width: 100%;
      height: 615px;
      /*background: url("../img/bg_engagements.png") no-repeat #fff;
      -webkit-background-size: cover;
      background-size: cover;*/
    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      height: 10px;
      width: 100%;
      //background: #D6D601;
    }
    }
}

.tua-model {
  position: relative;
  padding: 115px 40px 40px;
  height: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(51, 51, 43, 0.15);
  border-radius: 8px;
  @media (min-width: 576px) {
    padding: 115px 15px 40px;
  }
  @media (min-width: 768px) {
    padding: 238px 40px 40px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 40px;
    width: 60px;
    height: 60px;
    background: url("../img/engagement1.svg") no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    @media (min-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }
  h4 {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  p {
    @media (max-width: 768px){
      font-size: 14px;
      line-height: 20px;
    }
  }
}