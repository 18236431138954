.our-background {
  position: relative;
  background-color: #444;
  color: rgba(255,255,255, .7);
  padding-top: 40px;
  @media (min-width: 992px) {
    padding-top: 186px;
  }
  &:before {
    display: none;
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    top: 19px;
    right: 675/1920*100%;
    background: url("../img/our_background.svg") center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    @media (min-width: 992px) {
      display: block;
    }
  }
  h3 {
    color: #ffffff;
    margin-bottom: 40px;
  }
  @media (min-width: 1920px) {
    margin-bottom: 91px;
  }
  .tua-col-6 {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &__item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      width: calc(50% - 31px);
      padding-right: 31px;
      margin-bottom: 0;
    }
    &_horizontal {
      width: 100%;
      br {
        display: none;
      }
      @media (min-width: 768px) {
        display: flex;
        padding: 77px 0 77px 70px;
        br {
          display: block;
        }
      }
      &:first-child {
        @media (min-width: 768px) {
          border-bottom: 1px solid rgba(255,255,255, .7);
        }
      }
    }
  }
  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background: #D6D601;
    border-radius: 100%;
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 70px;
    line-height: 70px;
    letter-spacing: -0.03em;
    color: #33332B;
    text-align: center;
    @media (min-width: 1200px) {
      width: 199px;
      height: 199px;
      font-size: 100px;
      margin-bottom: 75px;
    }
  }
  &__icon {
    display: inline-block;
    background: url("../img/track.svg") center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    width: 90px;
    height: 76px;
    &_rocket {
      width: 72px;
      height: 72px;
      background: url("../img/startup.svg") center no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }
  p {
    @media (min-width: 768px) {
      padding-left: 40px;
    }
    @media (max-width: 768px){
      font-size: 16px;
      line-height: 24px;
    }
  }
  footer {
    display: flex;
    @media (min-width: 768px) {
      justify-content: center;
      padding-top: 22px;
    }
  }
}