$yellow: #D6D601;
$grey: #91917D;
$light-grey: #CECECE;
$black: #33332B;
$white: #ffffff;

::-webkit-input-placeholder { /* Edge */
  color: #9c9c9c;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9c9c9c;
}

::placeholder {
  color: #9c9c9c;
}

html,
body {
  margin: 0;
  height: 100%;
}

html {
  //scroll-behavior: smooth;
}

.full-screen {
  @media (min-width: 1920px) {
    height: 100vh;
    max-height: 1080px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

body {
  font: normal 18px/30px 'Montserrat', sans-serif;
  background: #444;
}

strong, b {
  font-weight: bold;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 20px;
  }
  @media (min-width: 1300px) and (max-width: 1920px) {
    font-size: 26px;
  }
  @media (min-width: 1920px) {
    font-size: 32px;
  }
}

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 50px;
  }
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #33332B;
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
  }
}

.tua-container {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1300px) {
    max-width: 1260px;
  }
  @media (min-width: 1700px) {
    max-width: 1595px;
  }
}

.tua-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.tua-col-9 {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% - 30px);
  @media (min-width: 1300px) {
    width: calc(9/12*100% - 30px);
  }
}

.tua-col-6 {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(50% - 30px);
  &_border-right {
    @media (min-width: 992px) {
      border-right: 1px solid rgba(255,255,255, .7);
    }
  }
  &_zero-padding {
    @media (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.tua-col-3 {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(25% - 30px);
}

:focus {
  outline: none;
}